import { Site } from "@inrange/building-manager-api-client/models-site";
import { Column, Grid, SummaryBoxMetrics } from "@inrange/theme-components";
import { OperationalSiteChart } from "@inrange/theme-components/charts";
import { tariffToFractionalCurrency } from "@inrange/theme-components/formatting";
import React from "react";
import { getLatestActualsMonthIndex } from "../../views/sitelist-utils";
import {
  getOperationalEnergySoldChartData,
  getOperationalRevenueChartData,
} from "./chartDataFormatting";
import { generateRevenueMetrics } from "./operational-utils";
import OperationalPerformanceRatio from "./OperationalPerformanceRatio";

const OperationalSummaryLandlord: React.FC<{ site: Site }> = ({ site }) => {
  const params = new URL(document.location.toString()).searchParams;
  const testMonthToday = Number(params.get("testMonthToday"));

  const revenueChartData = getOperationalRevenueChartData(site);
  const energySoldChartData = getOperationalEnergySoldChartData(site);

  const lastMonthIndex = getLatestActualsMonthIndex(site, testMonthToday);
  const lastMonthEnergyFlowActuals =
    site.energyFlowMonthlyActuals![lastMonthIndex!];
  const revenueMetrics = generateRevenueMetrics(
    site,
    lastMonthEnergyFlowActuals
  );
  const tariffMetrics: {
    value: string;
    valueSubText: string;
    dataTestId?: string;
  }[] = [
    {
      value: tariffToFractionalCurrency(site.tenantTariff, site.currencyCode),
      valueSubText: "On-site",
      dataTestId: "tenant-tariff",
    },
  ];
  if (
    site.energyFlowAnnual.networkExport + site.energyFlowAnnual.exported >
    0
  ) {
    tariffMetrics.push({
      value: tariffToFractionalCurrency(
        site.blendedExportTariff,
        site.currencyCode
      ),
      valueSubText: "Avg export",
    });
  }
  const tariffHeader = "Tariffs";
  const chartsHeight = "416px";

  return (
    <Grid $cols={1} $colwidth={"370px 1fr"} $gap={"10px"} $margin={"5px"}>
      <Column $height={"fit-content"}>
        <SummaryBoxMetrics
          metrics={tariffMetrics}
          header={tariffHeader}
          grid={true}
        />
        <SummaryBoxMetrics
          metrics={revenueMetrics}
          header={"Sold previous month"}
          grid={false}
        />
        <OperationalPerformanceRatio site={site} />
      </Column>
      <Column $height={"100%"}>
        <OperationalSiteChart
          header="Revenue"
          chartData={revenueChartData}
          toolTipText="The revenue generated from energy sold after the given revenue share during a given month, where energy be sold on-site or exported to the InRange network, at the relevant tariffs. For months where no data exists, the data reflects the forecasted revenue. Does not include VAT."
          currency={true}
          currencyCode={site.currencyCode}
          graphingExport={true}
          siteHasAnyExport={
            site.energyFlowAnnual.networkExport +
              site.energyFlowAnnual.exported >
            0
          }
          height={chartsHeight}
        />
        <OperationalSiteChart
          header="Energy sold"
          chartData={energySoldChartData}
          toolTipText="The amount of energy sold in kWh during a given month, where energy can be sold on-site or exported to the InRange network. For months where no data exists, the data reflects the forecasted amount."
          currency={false}
          graphingExport={true}
          siteHasAnyExport={
            site.energyFlowAnnual.networkExport +
              site.energyFlowAnnual.exported >
            0
          }
          height={chartsHeight}
        />
      </Column>
    </Grid>
  );
};

export default OperationalSummaryLandlord;
