import {
  MonthlyEnergyFlowActuals,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import formatCurrency from "../../../../theme-components/src/formatting/formatCurrency";
import { formatUnits } from "../../../../theme-components/src/formatting/formatKiloUnits";

export const generateProcuredMetrics = (
  site: Site,
  lastMonthEnergyFlowActuals: MonthlyEnergyFlowActuals | undefined
) => {
  const procurementTariff = site.networkImportTariff;
  const behindTheMeterTariff = site.tenantTariff;
  const lastMonthBehindMeter =
    lastMonthEnergyFlowActuals?.energyFlow?.behindMeter ?? 0;
  const lastMonthNetworkImport =
    lastMonthEnergyFlowActuals?.energyFlow?.networkImport ?? 0;
  const lastMonthCostBehindMeter = lastMonthBehindMeter * behindTheMeterTariff;
  const lastMonthCostNetworkImport = lastMonthNetworkImport * procurementTariff;
  const metrics = [
    {
      value: `${formatCurrency(lastMonthCostBehindMeter, site.currencyCode, 2)} (${formatUnits(lastMonthBehindMeter, "kWh", 2)})`,
      valueSubText: "From on-site generation",
      dataTestId: "procured-behind-meter-monthly",
    },
    {
      value: `${formatCurrency(lastMonthCostNetworkImport, site.currencyCode, 2)} (${formatUnits(lastMonthNetworkImport, "kWh", 2)})`,
      valueSubText: "Imported from network\nexcl. non-commodity costs",
      dataTestId: "procured-network-import-monthly",
    },
  ];
  return metrics;
};

export const generateRevenueMetrics = (
  site: Site,
  lastMonthEnergyFlowActuals: MonthlyEnergyFlowActuals | undefined
) => {
  const tenantTariff = site.tenantTariff;
  const exportTariff = site.inrangeExportTariff;
  const leaseRevenueShare = site.landlordLeaseRevenueShare;
  const lastMonthExport = lastMonthEnergyFlowActuals?.energyFlow?.exported ?? 0;
  const lastMonthRevenueExport =
    lastMonthExport * exportTariff * leaseRevenueShare;
  const lastMonthBehindMeter =
    lastMonthEnergyFlowActuals?.energyFlow?.behindMeter ?? 0;
  const lastMonthRevenueBehindMeter =
    lastMonthBehindMeter * tenantTariff * leaseRevenueShare;
  const metrics = [
    {
      value: `${formatCurrency(lastMonthRevenueBehindMeter, site.currencyCode, 2)} (${formatUnits(lastMonthBehindMeter, "kWh", 2)})`,
      valueSubText: "On-site behind the meter",
      dataTestId: "revenue-behind-meter-monthly",
    },
    {
      value: `${formatCurrency(lastMonthRevenueExport, site.currencyCode, 2)} (${formatUnits(lastMonthExport, "kWh", 2)})`,
      valueSubText: "Exported to network",
      dataTestId: "revenue-export-monthly",
    },
  ];

  return metrics;
};
