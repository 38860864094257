import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import {
  isOrganizationInSiteScores,
  loadSessionStoredValue,
  NoPaddedRow,
  saveSessionStoredValue,
  TableListSortFilters,
  TableListToggleButtons,
} from "@inrange/theme-components";
import { LandlordSiteListMetricCards } from "@inrange/theme-components/site";
import {
  LandlordSiteListHeader,
  LandlordSiteRow,
  Table,
  TableCellData,
} from "@inrange/theme-components/table";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../auth/UserContext";
import {
  calcEmissionsAvoided,
  calcSummaryCurrencyCode,
  calcTotalAnnualGeneration,
  calcTotalEnergyProcured,
  calcTotalProjectCost,
  calcTotalRevenue,
  calcTotalSavings,
} from "../routes/dashboard/aggregationCalculations";
import { generateParkFilteringSlackNotification } from "../routes/dashboard/dashboard-utils";
import calcIsOwnerOccupied from "../utils/calcIsOwnerOccupied";
import {
  filterSitesByText,
  sortAscendingForColumn,
  sortByColumn,
  togglesortAscending,
} from "./SiteListSortMethods";
import { sortOwnershipSelectOptions } from "./sitelist-utils";

const selectDefaultInvestmentModel = (
  isTenantOwnerOccupiedSiteList: boolean,
  leaseSitesCount: number,
  licenseSitesCount: number
): string => {
  if (isTenantOwnerOccupiedSiteList) {
    // Prefer lease
    if (leaseSitesCount > 0 || licenseSitesCount === 0) {
      return "lease";
    } else {
      return "license";
    }
  } else {
    // Prefer license
    if (licenseSitesCount > 0 || leaseSitesCount === 0) {
      return "license";
    } else {
      return "lease";
    }
  }
};

interface LandlordSiteListViewProps {
  sites: OrgSiteListEntry[];
  organisation: Organisation;
  isTenantOwnerOccupiedSiteList: boolean;
  isLeaseMetrics: boolean;
  tenantFilteringObject?: Record<string, { name: string; siteIDs: string[] }>;
  onTenantFiltering?: (filterAction: string, filterOrgNames: string[]) => void;
}

const LandlordSiteListView: React.FC<LandlordSiteListViewProps> = ({
  sites,
  organisation,
  isTenantOwnerOccupiedSiteList,
  isLeaseMetrics,
  tenantFilteringObject,
  onTenantFiltering,
}) => {
  const { user } = useContext(UserContext);
  const userEmail = user!.email;
  const orgID = organisation.id;
  const displayInRangeScoreCol = isOrganizationInSiteScores(orgID, sites);
  const allSitesNotStarted = sites.every(
    (site) => site.operationalStatus === "notStarted"
  );

  const [selectedTenantOrgIDs, setSelectedTenantOrgIDs] = useState<string[]>(
    () => {
      if (!tenantFilteringObject) {
        return [];
      }
      const storedValue = loadSessionStoredValue(organisation.id, "tenant");
      return storedValue ? (storedValue as string[]) : [];
    }
  );
  useEffect(() => {
    saveSessionStoredValue(organisation.id, "tenant", selectedTenantOrgIDs);
  }, [organisation, selectedTenantOrgIDs]);

  const [selectedParks, setSelectedParks] = useState<string[]>(() => {
    if (isTenantOwnerOccupiedSiteList) {
      return [];
    }
    const storedValue = loadSessionStoredValue(
      organisation.id,
      "landlord-park"
    );
    return storedValue ? (storedValue as string[]) : [];
  });
  useEffect(() => {
    saveSessionStoredValue(organisation.id, "landlord-park", selectedParks);
  }, [organisation, selectedParks]);

  const leaseSites = sites.filter(
    (site) => site.activeInvestmentModel === "lease"
  );
  const licenseSites = sites.filter(
    (site) => site.activeInvestmentModel === "license"
  );
  const originalLeaseSiteCount = leaseSites.length;
  const originalLicenseSiteCount = licenseSites.length;

  const [leaseSiteCount, setLeaseSiteCount] = useState<number>(
    originalLeaseSiteCount
  );
  const [licenseSiteCount, setLicenseSiteCount] = useState<number>(
    originalLicenseSiteCount
  );

  const defaultInvestmentModel = selectDefaultInvestmentModel(
    isTenantOwnerOccupiedSiteList,
    leaseSites.length,
    licenseSites.length
  );
  const sortColumnSuffix =
    defaultInvestmentModel === "license" ? "License" : "Lease";
  const defaultSortColumn =
    displayInRangeScoreCol || !allSitesNotStarted
      ? `siteStatus${sortColumnSuffix}`
      : `networkAvailability${sortColumnSuffix}`;
  const [sortColumn, setSortColumn] = useState<string>(defaultSortColumn);
  const [sortAscending, setSortAscending] = useState<boolean>(
    sortAscendingForColumn(defaultSortColumn)
  );
  sortByColumn(sites, sortColumn, sortAscending, organisation);

  const [textFilter, setTextFilter] = useState<string>("");
  const [filteredSites, setFilteredSites] = useState<OrgSiteListEntry[]>(
    sites.filter(
      (site) => site.activeInvestmentModel === defaultInvestmentModel
    )
  );
  const [investmentModel, setInvestmentModel] = useState<string>(
    defaultInvestmentModel
  );

  // Required metrics
  const siteCount = filteredSites.length;
  const summaryCurrencyCode = calcSummaryCurrencyCode(filteredSites);
  const totalAnnualGeneration = calcTotalAnnualGeneration(filteredSites);
  const totalEnergyProcured = calcTotalEnergyProcured(filteredSites);
  const emissionsAvoided = calcEmissionsAvoided(filteredSites);
  const totalProjectCost = calcTotalProjectCost(
    filteredSites,
    summaryCurrencyCode,
    investmentModel
  );
  const totalRevenue = calcTotalRevenue(
    organisation,
    filteredSites,
    investmentModel,
    summaryCurrencyCode
  );
  const totalSavings = calcTotalSavings(
    filteredSites,
    organisation,
    summaryCurrencyCode
  );

  const metrics = {
    totalSites: siteCount,
    totalRevenue: totalRevenue,
    totalOnSiteGeneration: totalAnnualGeneration,
    totalEmissionsAvoided: emissionsAvoided,
    totalEnergyProcured: totalEnergyProcured,
    totalSavings: totalSavings,
    totalProjectCost: totalProjectCost,
  };

  const changeInvestmentModel = (model: string) => {
    if (displayInRangeScoreCol || !allSitesNotStarted) {
      if (model === "license") {
        setSortColumn("siteStatusLicense");
      } else if (model === "lease") {
        setSortColumn("siteStatusLease");
      }
    } else {
      if (model === "license") {
        setSortColumn("networkAvailabilityLicense");
      } else if (model === "lease") {
        setSortColumn("networkAvailabilityLease");
      }
    }
    setInvestmentModel(model);
  };

  const generateModelTabCount = (model: string): string => {
    const count = model === "license" ? licenseSiteCount : leaseSiteCount;
    const originalCount =
      model === "license" ? originalLicenseSiteCount : originalLeaseSiteCount;
    return count !== originalCount
      ? `${count} of ${originalCount}`
      : originalCount.toString();
  };

  const modelSelectionTabs = {
    tab1: {
      label: defaultInvestmentModel === "license" ? "License" : "Lease",
      active: investmentModel === defaultInvestmentModel,
      onClick: () => {
        changeInvestmentModel(defaultInvestmentModel);
      },
      count:
        defaultInvestmentModel === "license"
          ? generateModelTabCount("license")
          : generateModelTabCount("lease"),
    },
    tab2: undefined,
    tab3: {
      label: defaultInvestmentModel === "license" ? "Lease" : "License",
      active: investmentModel !== defaultInvestmentModel,
      onClick: () => {
        changeInvestmentModel(
          defaultInvestmentModel === "license" ? "lease" : "license"
        );
      },
      count:
        defaultInvestmentModel === "license"
          ? generateModelTabCount("lease")
          : generateModelTabCount("license"),
    },
  };

  // Sort and filter
  useEffect(() => {
    let listedSites = [...sites];
    if (selectedTenantOrgIDs.length > 0 && tenantFilteringObject) {
      listedSites = listedSites.filter((site) =>
        selectedTenantOrgIDs.some((orgID) =>
          tenantFilteringObject[orgID].siteIDs.includes(site.id)
        )
      );
    }
    if (selectedParks.length > 0) {
      listedSites = listedSites.filter((site) => {
        const orgSitePark =
          site.siteOwnerships.find((so) => so.orgID === organisation.id)
            ?.park || "";
        return selectedParks.includes(orgSitePark);
      });
    }

    // Text search based on name, address or tenant name
    if (textFilter.length > 0) {
      listedSites = filterSitesByText(listedSites, textFilter);
    }
    sortByColumn(listedSites, sortColumn, sortAscending, organisation);

    const currentFilteredSiteCount = listedSites.length;
    listedSites = listedSites.filter(
      (site) => site.activeInvestmentModel === investmentModel
    );
    if (investmentModel === "lease") {
      setLeaseSiteCount(listedSites.length);
      setLicenseSiteCount(currentFilteredSiteCount - listedSites.length);
    } else {
      setLeaseSiteCount(currentFilteredSiteCount - listedSites.length);
      setLicenseSiteCount(listedSites.length);
    }

    setFilteredSites(listedSites);
  }, [
    investmentModel,
    organisation,
    selectedParks,
    selectedTenantOrgIDs,
    sites,
    sortAscending,
    sortColumn,
    tenantFilteringObject,
    textFilter,
  ]);

  const handleColumnClick = (columnKey: string) => {
    if (sortColumn === columnKey) {
      togglesortAscending(setSortAscending);
      return;
    }
    setSortColumn(columnKey);
    setSortAscending(sortAscendingForColumn(columnKey));
  };

  const onParkFiltering = (filterAction: string, filterParkNames: string[]) => {
    generateParkFilteringSlackNotification(
      filterAction,
      organisation.name,
      filterParkNames,
      userEmail
    );
  };

  // Pick the most common area unit
  const orgAreaUnit = Object.entries(
    filteredSites.reduce(
      (acc, site) => {
        if (!acc[site.areaUnit]) {
          acc[site.areaUnit] = 1;
        } else {
          acc[site.areaUnit] += 1;
        }
        return acc;
      },
      { ft: 0 }
    )
  ).sort((a, b) => b[1] - a[1])[0][0];

  const tableHead = (
    <LandlordSiteListHeader
      mode={investmentModel}
      sortColumn={sortColumn}
      sortAscending={sortAscending}
      handleColumnClick={handleColumnClick}
      orgAreaUnit={orgAreaUnit}
      displayInRangeScoreCol={displayInRangeScoreCol}
      allSitesNotStarted={allSitesNotStarted}
      showTenantColumn={!isTenantOwnerOccupiedSiteList}
    />
  );

  const tableRows = filteredSites.length ? (
    filteredSites.map((site) => {
      const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);

      const revenueModel = isOwnerOccupier
        ? site.financialModels.ownerOccupier
        : site.financialModels.landlord;

      const irr = revenueModel.license.irr;
      const payback = revenueModel.license.paybackMonths;

      return (
        <LandlordSiteRow
          site={site}
          organisation={organisation}
          mode={investmentModel}
          leaseRevenue={revenueModel.lease.revenue}
          licenseRevenue={revenueModel.license.revenue}
          irr={irr || 0}
          payback={payback ?? "N/A"}
          key={site.id}
          orgAreaUnit={orgAreaUnit}
          displayInRangeScoreCol={displayInRangeScoreCol}
          allSitesNotStarted={allSitesNotStarted}
          showTenantColumn={!isTenantOwnerOccupiedSiteList}
        />
      );
    })
  ) : (
    <LandlordNoSitesRow mode={investmentModel} />
  );

  const parkSelectOptions = organisation.parks
    .filter(
      (park) =>
        sites.filter((site) =>
          site.siteOwnerships.some((so) => so.park === park)
        ).length > 0
    )
    .sort()
    .map((park) => ({
      id: park,
      value: park,
      label: park,
    }));

  const tenantSelectOptions = sortOwnershipSelectOptions(
    Object.entries(tenantFilteringObject || {}).map(
      ([orgID, filterObject]) => ({
        id: orgID,
        value: filterObject.siteIDs,
        label: filterObject.name,
      })
    )
  );

  return (
    <>
      <LandlordSiteListMetricCards
        metrics={metrics}
        isLeaseMetrics={isLeaseMetrics}
        isTenantOwnerOccupiedSiteList={isTenantOwnerOccupiedSiteList}
        investmentModel={investmentModel}
        summaryCurrencyCode={summaryCurrencyCode}
      />
      <NoPaddedRow>
        <TableListToggleButtons tabs={modelSelectionTabs} />
        <TableListSortFilters
          textFilter={textFilter}
          setTextFilter={setTextFilter}
          parkFiltering={
            isTenantOwnerOccupiedSiteList || parkSelectOptions.length === 0
              ? undefined
              : {
                  setSelectedParks,
                  selectedParks,
                  parkSelectOptions,
                  onParkFiltering,
                }
          }
          orgFiltering={
            isTenantOwnerOccupiedSiteList
              ? undefined
              : {
                  setSelectedOrgIDs: setSelectedTenantOrgIDs,
                  onOwnershipFiltering: onTenantFiltering!,
                  selectedOrgIDs: selectedTenantOrgIDs,
                  ownershipSelectOptions: tenantSelectOptions,
                  isLandlord: true,
                }
          }
        />
      </NoPaddedRow>
      <NoPaddedRow>
        <Table
          head={tableHead}
          rows={tableRows}
          id={"landlord-site-list-table"}
        />
      </NoPaddedRow>
    </>
  );
};

const LandlordNoSitesRow: React.FC<{ mode: string }> = ({ mode }) => {
  const emptyDescription = `There are no sites currently set to ${mode}. You can change this on the site summary for any site.`;
  return (
    <tr>
      <td colSpan={5}>
        <TableCellData>{emptyDescription}</TableCellData>
      </td>
    </tr>
  );
};

export default LandlordSiteListView;
