import React from "react";
import {
  LastMonthGenerationHeader,
  LastMonthRevenueExportHeader,
  LastMonthRevenueOnsiteHeader,
  LastMonthSoldExportHeader,
  LastMonthSoldOnsiteHeader,
  SiteHeader,
} from "./OperationalSiteListHeaderTooltip";
import { TableHeaderCell } from "./TableHeaderCell";

const OperationalLandlordSiteListHeader: React.FC<{
  handleColumnClick: (columnName: string) => void;
  sortColumn: string;
  sortAscending: boolean;
}> = ({ handleColumnClick, sortColumn, sortAscending }) => {
  const headerData = [
    { columnName: "name", sortValue: "name", HeaderComponent: SiteHeader },
    {
      columnName: "lastMonthCost",
      sortValue: "lastMonthCost",
      HeaderComponent: LastMonthRevenueOnsiteHeader,
    },
    {
      columnName: "lastMonthRevenueExport",
      sortValue: "lastMonthRevenueExport",
      HeaderComponent: LastMonthRevenueExportHeader,
    },
    {
      columnName: "lastMonthSoldOnsite",
      sortValue: "lastMonthSoldOnsite",
      HeaderComponent: LastMonthSoldOnsiteHeader,
    },
    {
      columnName: "lastMonthSoldExport",
      sortValue: "lastMonthSoldExport",
      HeaderComponent: LastMonthSoldExportHeader,
    },
    {
      columnName: "lastMonthGeneration",
      sortValue: "lastMonthGeneration",
      HeaderComponent: LastMonthGenerationHeader,
    },
  ];

  return (
    <tr>
      {headerData.map((header) => (
        <TableHeaderCell
          key={header.columnName}
          handleColumnClick={handleColumnClick}
          columnName={header.columnName}
          sortColumn={sortColumn}
          sortAscending={sortAscending}
          sortValue={header.sortValue}
          HeaderComponent={header.HeaderComponent}
        />
      ))}
      <th></th>
    </tr>
  );
};

export default OperationalLandlordSiteListHeader;
