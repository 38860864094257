import {
  OrgSiteListEntry,
  Organisation,
} from "@inrange/building-manager-api-client/models-organisation";
import React from "react";
import formatCurrency from "../../formatting/formatCurrency";
import { formatUnits } from "../../formatting/formatKiloUnits";
import SiteLink from "./SiteLink";
import SiteNameAddressData from "./SiteNameAddressData";
import TableCellData from "./TableCellData";

const OperationalLandlordSiteRow: React.FC<{
  site: OrgSiteListEntry;
  organisation: Organisation;
  lastMonthRevenueOnsite: number;
  lastMonthRevenueExport: number;
  lastMonthSoldOnsite: number;
  lastMonthSoldExport: number;
  lastMonthGeneration: number;
}> = ({
  site,
  organisation,
  lastMonthRevenueOnsite,
  lastMonthRevenueExport,
  lastMonthSoldOnsite,
  lastMonthSoldExport,
  lastMonthGeneration,
}) => {
  return (
    <tr key={site.id} id={site.id}>
      <td>
        <TableCellData>
          <SiteNameAddressData
            siteName={site.name}
            siteAddress={site.address}
            siteId={site.id}
            orgId={organisation.id}
          />
        </TableCellData>
      </td>
      <td>
        <TableCellData>
          {formatCurrency(lastMonthRevenueOnsite, site.currencyCode, 2)}
        </TableCellData>
      </td>
      <td>
        <TableCellData>
          {formatCurrency(lastMonthRevenueExport, site.currencyCode, 2)}
        </TableCellData>
      </td>
      <td>
        <TableCellData>
          {formatUnits(lastMonthSoldOnsite, "kWh", 2)}
        </TableCellData>
      </td>
      <td>
        <TableCellData>
          {formatUnits(lastMonthSoldExport, "kWh", 2)}
        </TableCellData>
      </td>
      <td>
        <TableCellData>
          {formatUnits(lastMonthGeneration, "kWh", 2)}
        </TableCellData>
      </td>
      <td style={{ width: "170px" }}>
        <SiteLink siteId={site.id} orgId={organisation.id} />
      </td>
    </tr>
  );
};

export default OperationalLandlordSiteRow;
