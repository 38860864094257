import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import { NoPaddedRow, TableListSortFilters } from "@inrange/theme-components";
import {
  OperationalLandlordSiteListHeader,
  OperationalLandlordSiteRow,
  Table,
} from "@inrange/theme-components/table";
import React, { useState } from "react";
import {
  sortAscendingForColumn,
  sortByColumn,
  togglesortAscending,
} from "./SiteListSortMethods";
import {
  getBehindMeterRevenue,
  getExportRevenue,
  getLatestActualsMonthIndex,
} from "./sitelist-utils";

interface OperationalLandlordSiteListViewProps {
  operationalSites: OrgSiteListEntry[];
  organisation: Organisation;
  startingSortColumn: string;
}

const OperationalLandlordSiteListView: React.FC<
  OperationalLandlordSiteListViewProps
> = ({ operationalSites, organisation, startingSortColumn }) => {
  const params = new URL(document.location.toString()).searchParams;
  const testMonthToday = Number(params.get("testMonthToday"));

  const [textFilter, setTextFilter] = useState("");
  const [sortColumn, setSortColumn] = useState(startingSortColumn);
  const [sortAscending, setSortAscending] = useState(
    sortAscendingForColumn(startingSortColumn)
  );
  sortByColumn(operationalSites, sortColumn, sortAscending, organisation);

  const handleColumnClick = (columnKey: string) => {
    if (sortColumn === columnKey) {
      togglesortAscending(setSortAscending);
      return;
    }
    setSortColumn(columnKey);
    setSortAscending(sortAscendingForColumn(columnKey));
  };

  const tableHead = (
    <OperationalLandlordSiteListHeader
      sortColumn={sortColumn}
      sortAscending={sortAscending}
      handleColumnClick={handleColumnClick}
    />
  );

  const tableRows = operationalSites.map((site) => {
    let lastMonthRevenueOnsite = 0;
    let lastMonthRevenueExport = 0;
    let lastMonthSoldOnsite = 0;
    let lastMonthSoldExport = 0;
    let lastMonthGeneration = 0;
    if (site.energyFlowMonthlyActuals !== undefined) {
      const lastMonthIndex = getLatestActualsMonthIndex(site, testMonthToday);

      if (lastMonthIndex !== undefined) {
        const lastMonthEnergyFlows =
          site.energyFlowMonthlyActuals[lastMonthIndex].energyFlow;

        lastMonthRevenueOnsite = getBehindMeterRevenue(
          lastMonthEnergyFlows.matchedEnergy
        );
        lastMonthRevenueExport = getExportRevenue(
          lastMonthEnergyFlows.matchedEnergy
        );
        lastMonthSoldOnsite = lastMonthEnergyFlows.behindMeter;
        lastMonthSoldExport = lastMonthEnergyFlows.exported;
        lastMonthGeneration = lastMonthEnergyFlows.generation;
      }
    }

    return (
      <OperationalLandlordSiteRow
        key={site.id}
        site={site}
        organisation={organisation}
        lastMonthRevenueOnsite={lastMonthRevenueOnsite}
        lastMonthRevenueExport={lastMonthRevenueExport}
        lastMonthSoldOnsite={lastMonthSoldOnsite}
        lastMonthSoldExport={lastMonthSoldExport}
        lastMonthGeneration={lastMonthGeneration}
      />
    );
  });

  return (
    <>
      <NoPaddedRow style={{ marginTop: "8px" }}>
        <TableListSortFilters
          textFilter={textFilter}
          setTextFilter={setTextFilter}
        />
      </NoPaddedRow>
      <NoPaddedRow>
        <Table head={tableHead} rows={tableRows} />
      </NoPaddedRow>
    </>
  );
};

export default OperationalLandlordSiteListView;
