import { useSiteSdm } from "@inrange/building-manager-api-client";
import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import { Site } from "@inrange/building-manager-api-client/models-site";
import {
  Column,
  ColumnNoDefaults,
  EnergyDemandSidebar,
  EnergySupplySidebar,
  Row,
  SimpleButton,
  Tooltip,
} from "@inrange/theme-components";
import {
  PeakOffPeakChart,
  YearDayEnergyChart,
} from "@inrange/theme-components/charts";
import {
  formatCurrencyToNearestTen,
  fractionalCurrencySymbol,
} from "@inrange/theme-components/formatting";
import {
  MarketplaceEnergyCurvesModal,
  siteCanBuy,
  siteCanSell,
} from "@inrange/theme-components/marketplace";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "../../auth/UserContext";
import { formatBuyDescription } from "../../utils/sdm";
import { getProposalType } from "../../utils/utils";
import {
  genEnergyProcuredChartData,
  genPeakOffPeakChartDataYear,
} from "./chartDataFormatting";
import NetworkSitesBar from "./NetworkSitesBar";

interface EnergySavingsProps {
  organisation: Organisation;
  site: Site;
  investmentModel: string;
  isOwnerOccupier: boolean;
  isGeneratingSite: boolean;
}

const EnergySavings: React.FC<EnergySavingsProps> = ({
  organisation,
  site,
  investmentModel,
  isOwnerOccupier,
  isGeneratingSite,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlSpillSiteId = params.get("spillSiteId") || undefined;
  const { user } = useContext(UserContext);
  const { fetchSiteSdmList, bestBuyOffer } = useSiteSdm({
    siteId: site.id,
    onlyLinkedOrgs: !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE,
    userOrgId: organisation.id,
  });
  const [energyProcuredChartPeriod, setEnergyProcuredChartPeriod] = useState<
    "year" | "summer" | "winter"
  >("year");
  const [showEnergyCurvesModal, setShowEnergyCurvesModal] =
    useState<boolean>(false);

  const isBatterySite = !!site.costInputsBattery.batterySpec;
  const proposalType = getProposalType(
    organisation.orgType,
    isGeneratingSite,
    isOwnerOccupier
  );

  const hasWiredBuy = site.sdmMatches.some(
    (match) => match.buyerId === site.id && match.isWired
  );
  const isNotYetProcuringSite =
    site.energyFlowAnnual.behindMeter + site.energyFlowAnnual.networkImport ===
      0 && site.energyFlowAnnual.demand > 0;

  let annualSavings = site.financialModels.tenant.savings;
  let lifetimeSavings = site.financialModels.tenant.lifetimeSavings;
  let displayTariff = (site.tenantTariff * 100).toFixed(2);

  if (isOwnerOccupier) {
    annualSavings =
      site.financialModels.ownerOccupier[site.activeInvestmentModel].savings;
    lifetimeSavings =
      site.financialModels.ownerOccupier[site.activeInvestmentModel]
        .lifetimeSavings!;
    if (site.activeInvestmentModel === "license") {
      displayTariff = "0";
    }
  }

  const energyProcuredChartData = genEnergyProcuredChartData(
    site,
    energyProcuredChartPeriod
  );
  const peakOffPeakChartData = genPeakOffPeakChartDataYear(site);

  const peakTotal =
    site.energyFlowAnnual.peakBehindMeter +
    site.energyFlowAnnual.peakInRange +
    site.energyFlowAnnual.peakGrid;
  const peakInRangePerc =
    peakTotal > 0
      ? (site.energyFlowAnnual.peakBehindMeter +
          site.energyFlowAnnual.peakInRange) /
        peakTotal
      : 0;
  const daylightInRangePerc =
    site.energyFlowAnnual.daylightBehindMeter !== undefined &&
    site.energyFlowAnnual.daylightInRange !== undefined &&
    site.energyFlowAnnual.daylightDemand !== undefined &&
    site.energyFlowAnnual.daylightDemand > 0
      ? (site.energyFlowAnnual.daylightBehindMeter +
          site.energyFlowAnnual.daylightInRange) /
        site.energyFlowAnnual.daylightDemand
      : undefined;

  const marketTariff = (site.marketTariff * 100).toFixed(2);

  const generateBuyEnergyBlock = () => {
    return siteCanBuy(organisation, site) &&
      (isGeneratingSite ||
        isOwnerOccupier ||
        organisation.orgType === "Tenant") ? (
      <Column
        style={{ width: "90%" }}
        $margin={"0 15px 15px 15px"}
        $padding={"0 8px"}
        $justifyContent={"flex-end"}
      >
        <div
          style={{
            fontWeight: "500",
            fontSize: "14px",
            margin: "auto 0px",
          }}
        >
          {fetchSiteSdmList.isLoading && (
            <span>Finding a Marketplace buy offer…</span>
          )}
          {!fetchSiteSdmList.isLoading && (
            <>
              {formatBuyDescription(
                organisation,
                site,
                investmentModel,
                bestBuyOffer!,
                false,
                true
              )}
            </>
          )}
        </div>
        <SimpleButton
          fontWeight="400"
          width={"35%"}
          label={`Buy ${isNotYetProcuringSite ? "" : "more"} energy`}
          onClick={() => {
            trackUserInteraction(
              {
                organisation_name: organisation.name,
                site_id: site.id,
                site_name: site.name,
                site_operational_status: site.operationalStatus,
                label: `Buy ${isNotYetProcuringSite ? "" : "more"} energy`,
                button_location: "site metrics tile",
              },
              "ENERGY_OFFER",
              "ENERGY_OFFER_SITE_BUTTON_CLICK",
              user!.email.toLowerCase(),
              "customer-app"
            );
          }}
          to={`/org/${organisation.id}/marketplace?siteId=${site.id}&offerType=buy`}
          fontColor={"white"}
          background={"#00022F"}
        />
      </Column>
    ) : undefined;
  };

  const showTenantNamesInHeader =
    organisation.orgType === "Landlord" &&
    site.siteOwnerships.some((so) => so.ownership === "tenant");
  const tenantNames = site.siteOwnerships
    .filter((so) => so.ownership === "tenant")
    .map((so) => so.name!)
    .sort();

  return (
    <>
      <Row>
        <SavingsMetric>
          <MetricTooltip>
            {showTenantNamesInHeader && (
              <h3>
                {tenantNames[0]}
                {"'"}
                {tenantNames[0].toLowerCase().endsWith("s") ? "" : "s"}{" "}
                {tenantNames.length > 1 && (
                  <Tooltip text={tenantNames.slice(1).join(", ")}>
                    <span>
                      and{" "}
                      <span
                        style={{
                          textDecoration: "dotted underline 2px",
                        }}
                      >
                        {tenantNames.length - 1} others{"'"}
                      </span>
                      &nbsp;
                    </span>
                  </Tooltip>
                )}
                savings with InRange
              </h3>
            )}
            {!showTenantNamesInHeader && <h3>Savings from InRange</h3>}
            <Tooltip
              text={`The total saved from the PV system and InRange network is the forecasted energy demand over ${site.systemLifetimeYears} years, multiplied by the difference between the current market tariff and the InRange tariff.`}
            />
          </MetricTooltip>
          <h5>
            Projected savings from the PV system and InRange network for this
            site given a starting market tariff of {marketTariff}{" "}
            {fractionalCurrencySymbol(site.currencyCode)}/kWh.
          </h5>
          <MetricRow>
            <Metric>
              <h4>
                {formatCurrencyToNearestTen(
                  lifetimeSavings,
                  site.currencyCode,
                  0,
                  false
                )}
              </h4>
              <h5>Total saved over {site.systemLifetimeYears} years</h5>
            </Metric>
            <Metric>
              <h4>
                {formatCurrencyToNearestTen(
                  annualSavings,
                  site.currencyCode,
                  0,
                  false
                )}
              </h4>
              <h5>Saved in year 1</h5>
            </Metric>
          </MetricRow>
        </SavingsMetric>
        <TariffMetric>
          <MetricTooltip>
            <h3>InRange tariff</h3>
            <Tooltip
              text={
                "InRange offers fixed energy tariffs for a specified number of years. This tariff is based on the site profile, including the energy generation potential and the energy demand."
              }
            />
          </MetricTooltip>

          <h5>Cost of energy procured from InRange</h5>
          <MetricRow>
            {(isGeneratingSite || hasWiredBuy) && (
              <Metric>
                <h4>
                  {displayTariff +
                    fractionalCurrencySymbol(site.currencyCode) +
                    "/kWh"}
                </h4>
                <h5>
                  {!isGeneratingSite && hasWiredBuy ? "Wired" : "On-site"}
                </h5>
              </Metric>
            )}
            <Metric>
              <h4>
                {(site.networkImportTariff * 100.0).toFixed(2) +
                  fractionalCurrencySymbol(site.currencyCode) +
                  "/kWh"}
              </h4>
              <h5>InRange network</h5>
              <h5>excl. non-commodity costs</h5>
            </Metric>
          </MetricRow>
        </TariffMetric>
      </Row>
      <Row>
        <ColumnNoDefaults style={{ flexGrow: 1 }}>
          <NetworkSitesBar
            site={site}
            organisation={organisation}
            setShowEnergyCurvesModal={setShowEnergyCurvesModal}
            allowShowSellBar={false}
            margin={"-24px 12px 8px 12px"}
          />
          <YearDayEnergyChart
            header={"Amount of energy procured"}
            chartData={energyProcuredChartData}
            isGeneratingSite={isGeneratingSite}
            isBatterySite={isBatterySite}
            isNotYetProcuringSite={
              proposalType === "landlord" ? false : isNotYetProcuringSite
            }
            buyEnergyBlock={generateBuyEnergyBlock()}
            energyProcuredChartPeriod={energyProcuredChartPeriod}
            setEnergyProcuredChartPeriod={setEnergyProcuredChartPeriod}
            height="420px"
          />
        </ColumnNoDefaults>

        <EnergyDemandSidebar
          behindMeter={site.energyFlowAnnual.behindMeter}
          inrangeNetwork={site.energyFlowAnnual.networkImport}
          grid={site.energyFlowAnnual.gridImport}
          isGeneratingSite={isGeneratingSite}
          isBatterySite={isBatterySite}
        />
      </Row>
      <Row>
        <ColumnNoDefaults style={{ flexGrow: 1 }}>
          <PeakOffPeakChart
            chartData={peakOffPeakChartData}
            totalOnSiteGeneration={site.energyFlowAnnual.generation}
            isGeneratingSite={isGeneratingSite}
            height="730px"
          />
        </ColumnNoDefaults>

        <EnergySupplySidebar
          isGeneratingSite={isGeneratingSite}
          peakInRange={peakInRangePerc}
          daylightInRange={daylightInRangePerc}
        />
      </Row>
      {showEnergyCurvesModal && (
        <MarketplaceEnergyCurvesModal
          organisation={organisation}
          site={site}
          offerType={siteCanSell(organisation, site) ? "sell" : "buy"}
          setShowModal={setShowEnergyCurvesModal}
          urlSpillSiteId={urlSpillSiteId}
        />
      )}
    </>
  );
};

export default EnergySavings;

const MetricTooltip = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SavingsMetric = styled.div`
  padding: 16px 16px;
  margin: 16px 0px 16px 0px;
  border-radius: 8px;
  flex-grow: 1;
  background: #eee;
  height: 164px;

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    padding: 0;
    margin: 0 0 8px;
  }
  h4 {
    font-size: 25px;
    font-weight: 600;
    line-height: 25px;
    padding: 0;
    margin: 8px 0;
  }
  h5 {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding: 0;
    margin: 0;
  }
`;

const TariffMetric = styled(SavingsMetric)`
  width: 470px;
  margin-left: 16px;
  flex-grow: 0;
`;

const Metric = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;
const MetricRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
