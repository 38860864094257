import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../auth/UserContext";
import LandlordSiteListView from "../../views/LandlordSiteListView";
import OperationalLandlordSiteListView from "../../views/OperationalLandlordSiteListView";
import {
  generateOwnershipFilteringObject,
  generateOwnershipFilteringSlackNotification,
  getSitesByType,
} from "./dashboard-utils";

interface LandlordSiteListProps {
  organisation: Organisation;
  sites: OrgSiteListEntry[];
  isLeaseMetrics: boolean;
}

const LandlordSiteList: React.FC<LandlordSiteListProps> = ({
  organisation,
  sites,
  isLeaseMetrics,
}) => {
  const { user } = useContext(UserContext);
  const userEmail = user!.email;
  let { siteType } = useParams<{ siteType: string }>();
  const orgID = organisation.id;

  const [sitesByType, landlordSites] = useMemo(() => {
    const newSitesByType = getSitesByType(sites, orgID, [
      "landlord",
      "ownerOccupier",
      "operational",
    ]);
    return [
      newSitesByType,
      [...newSitesByType["landlord"], ...newSitesByType["ownerOccupier"]],
    ];
  }, [sites, orgID]);

  if (siteType === undefined) {
    if (landlordSites.length > 0) {
      siteType = "landlord";
    } else {
      siteType = "operational";
    }
  }

  // Generate filtering object for tenants
  // Shape - { [orgID]: { name: string, siteIDs: string[] } }
  const tenantFilteringObject = generateOwnershipFilteringObject(
    sitesByType[siteType],
    "tenant"
  );

  if (siteType === "operational") {
    return (
      <OperationalLandlordSiteListView
        operationalSites={sitesByType["operational"]}
        organisation={organisation}
        startingSortColumn={"name"}
      />
    );
  }

  const onTenantFiltering = (
    filterAction: string,
    filterOrgNames: string[]
  ) => {
    generateOwnershipFilteringSlackNotification(
      filterAction,
      organisation.name,
      filterOrgNames,
      userEmail
    );
  };

  return (
    <LandlordSiteListView
      sites={landlordSites}
      organisation={organisation}
      isLeaseMetrics={isLeaseMetrics}
      isTenantOwnerOccupiedSiteList={false}
      tenantFilteringObject={tenantFilteringObject}
      onTenantFiltering={onTenantFiltering}
    />
  );
};

export default LandlordSiteList;
