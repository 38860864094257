import { SiteFile } from "@inrange/building-manager-api-client/models-site";

type SortFunction = (
  listedFiles: SiteFile[],
  sortAscending: boolean,
  sortColumn?: string
) => void;

export const sortByDescription: SortFunction = (listedFiles, sortAscending) => {
  listedFiles.sort((a, b) => {
    const aDescription = a.description || "";
    const bDescription = b.description || "";
    return sortAscending
      ? aDescription.localeCompare(bDescription)
      : bDescription.localeCompare(aDescription);
  });
};

export const sortInvoiceStatementNumber: SortFunction = (
  listedFiles,
  sortAscending
) => {
  listedFiles.sort((a, b) => {
    const aCompareValue = a.invoiceNumber
      ? a.invoiceNumber
      : a.statementNumber || "";
    const bCompareValue = b.invoiceNumber
      ? b.invoiceNumber
      : b.statementNumber || "";

    return sortAscending
      ? aCompareValue.localeCompare(bCompareValue)
      : bCompareValue.localeCompare(aCompareValue);
  });
};

export const sortByProperty = (
  listedFiles: SiteFile[],
  sortAscending: boolean,
  propertyAccessor: (file: SiteFile) => number
) => {
  listedFiles.sort((a, b) => {
    const propertyA = propertyAccessor(a);
    const propertyB = propertyAccessor(b);
    return sortAscending ? propertyA - propertyB : propertyB - propertyA;
  });
};

export const sortInvoiceStatementAmount: SortFunction = (
  listedFiles,
  sortAscending
) => {
  sortByProperty(listedFiles, sortAscending, (file) => {
    return file.invoiceAmount ? file.invoiceAmount : file.statementAmount || 0;
  });
};

export const sortByDate: SortFunction = (
  listedFiles,
  sortAscending,
  sortColumn
) => {
  sortByProperty(listedFiles, sortAscending, (file) => {
    return new Date(file[sortColumn!]).getTime();
  });
};

export function sortByColumn(
  listedFiles: SiteFile[],
  sortColumn: string,
  sortAscending: boolean
) {
  const sortFunctions: { [key: string]: SortFunction } = {
    description: sortByDescription,
    number: sortInvoiceStatementNumber,
    amount: sortInvoiceStatementAmount,
    date: sortByDate,
    invoiceDueDate: sortByDate,
    issueDate: sortByDate,
  };

  const sortFunction = sortFunctions[sortColumn];

  if (sortFunction) {
    if (sortFunction === sortByDate) {
      sortFunction(listedFiles, sortAscending, sortColumn);
    } else {
      sortFunction(listedFiles, sortAscending);
    }
  }
}

export const filterFilesByText = (
  files: SiteFile[],
  textFilter: string
): SiteFile[] => {
  return files.filter((file) =>
    (file.description || "").toLowerCase().includes(textFilter.toLowerCase())
  );
};

export const toggleSortAscending = (
  setSortAscending: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setSortAscending((prevSortAscending) => !prevSortAscending);
};
