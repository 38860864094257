import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import { Site } from "@inrange/building-manager-api-client/models-site";
import {
  InheritColorLink,
  siteCanBuy,
  siteCanSell,
} from "@inrange/theme-components/marketplace";
import React from "react";

interface NetworkSitesBarProps {
  site: Site;
  organisation: Organisation;
  setShowEnergyCurvesModal: (showModal: boolean) => void;
  allowShowSellBar: boolean;
  margin: string;
}

const NetworkSitesBar: React.FC<NetworkSitesBarProps> = ({
  site,
  organisation,
  setShowEnergyCurvesModal,
  allowShowSellBar,
  margin,
}) => {
  // We always treat the selling network sites bar as if we're in the license model
  const isSiteCanSell = siteCanSell(organisation, {
    ...site,
    activeInvestmentModel: "license",
  });
  const isSiteCanBuy = siteCanBuy(organisation, site);
  const numNetworkImportSites = site.sdmMatches.filter(
    (match) => match.buyerId === site.id
  ).length;
  const numNetworkExportSites =
    site.sdmMatches.filter((match) => match.sellerId === site.id).length +
    (site.energyFlowAnnual.exported > 0 ? 1 : 0);
  const totalExport =
    site.energyFlowAnnual.networkExport + site.energyFlowAnnual.exported;
  const showSellBar = allowShowSellBar && isSiteCanSell && totalExport > 0;
  return (
    <>
      {showSellBar && (
        <div style={{ margin }}>
          Energy{" "}
          {site.energyFlowAnnual.behindMeter > 0 ? "sold on-site and " : ""}
          exported to{" "}
          <InheritColorLink
            href="#"
            onClick={() => setShowEnergyCurvesModal(true)}
            style={{ color: "black" }}
          >
            {numNetworkExportSites} site{numNetworkExportSites > 1 && "s"}
          </InheritColorLink>{" "}
          on the InRange Marketplace
        </div>
      )}
      {!showSellBar && isSiteCanBuy && numNetworkImportSites > 0 && (
        <div style={{ margin }}>
          Procuring from{" "}
          {site.energyFlowAnnual.behindMeter > 0
            ? "on-site generation and "
            : ""}
          <InheritColorLink
            href="#"
            onClick={() => setShowEnergyCurvesModal(true)}
            style={{ color: "black" }}
          >
            {numNetworkImportSites} sites
          </InheritColorLink>{" "}
          on the InRange Marketplace
        </div>
      )}
    </>
  );
};

export default NetworkSitesBar;
