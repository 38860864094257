import { KINDE_ENVIRONMENT } from "@inrange/auth";
import { Loading } from "@inrange/theme-components";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import getAdminAppDomain from "../utils/getAdminAppDomain";
import { UserContext } from "./UserContext";

const SiteRedirect = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { siteId } = useParams<{ siteId: string }>();

  useEffect(() => {
    if (navigate && user) {
      const allowAllOrgs =
        user.kinde_org_code === KINDE_ENVIRONMENT.KINDE_INRANGE_ORG_CODE;
      if (!allowAllOrgs) {
        navigate(`/org/${user.org_id}/site/${siteId}`, { replace: true });
      } else {
        window.location.replace(`${getAdminAppDomain()}/site/${siteId}/edit`);
      }
    }
  }, [navigate, siteId, user]);

  return <Loading label="Logging in ..." />;
};

export default SiteRedirect;
