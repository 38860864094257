import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import { SiteOwnership } from "@inrange/building-manager-api-client/models-site";
import { GBP_TO_EUR } from "@inrange/theme-components/formatting";
import calcIsOwnerOccupied from "../../utils/calcIsOwnerOccupied";
import { getBehindMeterRevenue } from "../../views/sitelist-utils";

export const calcSummaryCurrencyCode = (sites: OrgSiteListEntry[]): string => {
  const siteCurrencyCodes = sites.reduce((acc, site) => {
    acc.add(site.currencyCode);
    return acc;
  }, new Set<string>());
  if (siteCurrencyCodes.size > 1) {
    return "GBP";
  }
  return siteCurrencyCodes.values().next().value!;
};

const roundToTwoDecimalPlaces = (value: number): number => {
  return parseFloat(value.toFixed(2));
};

const getCurrencyValueForCurrencyCode = (
  summaryCurrencyCode: string,
  siteCurrencyCode: string,
  value: number
): number => {
  if (siteCurrencyCode === summaryCurrencyCode) {
    return value;
  }
  return roundToTwoDecimalPlaces(value * (1 / GBP_TO_EUR));
};

export const calcTotalAnnualGeneration = (
  sites: OrgSiteListEntry[]
): number => {
  const totalAnnualGeneration = sites.reduce((acc, site) => {
    return acc + site.energyFlowAnnual.generation;
  }, 0);
  return totalAnnualGeneration;
};

export const calcTotalAnnualDemand = (sites: OrgSiteListEntry[]): number => {
  const totalAnnualDemand = sites.reduce((acc, site) => {
    return acc + site.energyFlowAnnual.demand;
  }, 0);
  return totalAnnualDemand;
};

export const calcTotalEnergyProcured = (sites: OrgSiteListEntry[]): number => {
  const totalEnergyProcured = sites.reduce((acc, site) => {
    return acc + site.energyFlowAnnual.procurement;
  }, 0);
  return totalEnergyProcured;
};

export const calcTotalEnergyProcuredCost = (
  sites: OrgSiteListEntry[],
  summaryCurrencyCode: string
): number => {
  const totalEnergyProcuredCost = sites.reduce((acc, site) => {
    const value = getBehindMeterRevenue(site.energyFlowAnnual.matchedEnergy);
    return (
      acc +
      getCurrencyValueForCurrencyCode(
        summaryCurrencyCode,
        site.currencyCode,
        value
      )
    );
  }, 0);
  return totalEnergyProcuredCost;
};

export const calcEmissionsAvoided = (sites: OrgSiteListEntry[]): number => {
  const emissionsAvoided = sites.reduce((acc, site) => {
    return acc + Number(site.calculations.emissionsAvoided.totalAvoidance);
  }, 0);
  return emissionsAvoided;
};

export const calcTotalInitialCost = (sites: OrgSiteListEntry[]): number => {
  const totalInitialCost = sites.reduce((acc, site) => {
    return acc + site.projectCosts.initialInvestment;
  }, 0);
  return totalInitialCost;
};

export const calcTotalMaintenanceCost = (sites: OrgSiteListEntry[]): number => {
  const totalMaintenanceCost = sites.reduce((acc, site) => {
    return acc + site.projectCosts.maintenance;
  }, 0);
  return totalMaintenanceCost;
};

export const calcTotalProjectCost = (
  sites: OrgSiteListEntry[],
  summaryCurrencyCode: string,
  investmentModel: string
): number => {
  if (investmentModel === "lease") {
    return 0;
  }
  const totalProjectCost = sites.reduce((acc, site) => {
    return (
      acc +
      getCurrencyValueForCurrencyCode(
        summaryCurrencyCode,
        site.currencyCode,
        site.projectCosts.total
      )
    );
  }, 0);
  return totalProjectCost;
};

export const calcTotalSavings = (
  sites: OrgSiteListEntry[],
  organisation: Organisation,
  summaryCurrencyCode: string
): number => {
  let totalRevenue = 0;
  totalRevenue = sites.reduce((acc, site) => {
    const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);
    const value = isOwnerOccupier
      ? site.financialModels.ownerOccupier.lease.savings
      : site.financialModels.tenant.savings;
    return (
      acc +
      getCurrencyValueForCurrencyCode(
        summaryCurrencyCode,
        site.currencyCode,
        value
      )
    );
  }, 0);
  return totalRevenue;
};

export const calcTotalOnSiteGenerationConsumed = (
  sites: OrgSiteListEntry[]
): number => {
  const totalOnSiteGenerationConsumed = sites.reduce((acc, site) => {
    return acc + site.energyFlowAnnual.behindMeter;
  }, 0);
  return totalOnSiteGenerationConsumed;
};

export const calcTotalNetworkImport = (sites: OrgSiteListEntry[]): number => {
  const totalNetworkImport = sites.reduce((acc, site) => {
    return acc + site.energyFlowAnnual.networkImport;
  }, 0);
  return totalNetworkImport;
};

export const calcTotalGridImport = (sites: OrgSiteListEntry[]): number => {
  const totalGridImport = sites.reduce((acc, site) => {
    return acc + site.energyFlowAnnual.gridImport;
  }, 0);
  return totalGridImport;
};

export const calcAverageTariff = (
  sites: OrgSiteListEntry[],
  summaryCurrencyCode: string
): number => {
  if (sites.length === 0) return 0;
  const totalTariff = sites.reduce((acc, site) => {
    return (
      acc +
      getCurrencyValueForCurrencyCode(
        summaryCurrencyCode,
        site.currencyCode,
        site.tenantTariff
      )
    );
  }, 0);
  return roundToTwoDecimalPlaces(totalTariff / sites.length);
};

export const calcAverageMarketTariff = (
  sites: OrgSiteListEntry[],
  summaryCurrencyCode: string
): number => {
  if (sites.length === 0) return 0;
  const totalTariff = sites.reduce((acc, site) => {
    return (
      acc +
      getCurrencyValueForCurrencyCode(
        summaryCurrencyCode,
        site.currencyCode,
        site.marketTariff
      )
    );
  }, 0);
  return roundToTwoDecimalPlaces(totalTariff / sites.length);
};

export const countSitesByOwnershipAndOperationalStatus = (
  sites: OrgSiteListEntry[],
  orgSiteOwnerships: SiteOwnership[]
): Record<string, number> => {
  const siteById = sites.reduce(
    (acc, site) => {
      return { ...acc, [site.id]: site };
    },
    {} as Record<string, OrgSiteListEntry>
  );
  const siteOwnershipsWithOperationalStatus = orgSiteOwnerships.map(
    (siteOwnership) => {
      const operationalStatus =
        siteById[siteOwnership.siteID!]?.operationalStatus;
      return {
        ...siteOwnership,
        operationalStatus,
      };
    }
  );

  const countSitesByOwnership = siteOwnershipsWithOperationalStatus.reduce(
    (acc, siteOwnershipWithOperationalStatus) => {
      if (
        siteOwnershipWithOperationalStatus.operationalStatus === "operational"
      ) {
        acc["operational"] = acc["operational"] + 1 || 1;
      } else {
        acc[siteOwnershipWithOperationalStatus.ownership] =
          acc[siteOwnershipWithOperationalStatus.ownership] + 1 || 1;
      }
      return acc;
    },
    {} as Record<string, number>
  );
  return countSitesByOwnership;
};

export const calcTotalRevenue = (
  organisation: Organisation,
  sites: OrgSiteListEntry[],
  investmentModel: string,
  summaryCurrencyCode: string
): number => {
  let totalRevenue = 0;
  totalRevenue = sites.reduce((acc, site) => {
    const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);
    const value = isOwnerOccupier
      ? site.financialModels.ownerOccupier[investmentModel].revenue
      : site.financialModels.landlord[investmentModel].revenue;
    return (
      acc +
      getCurrencyValueForCurrencyCode(
        summaryCurrencyCode,
        site.currencyCode,
        value
      )
    );
  }, 0);
  return totalRevenue;
};

export const calcTotalRoofspace = (sites: OrgSiteListEntry[]): number => {
  const total = sites.reduce((acc, site) => {
    return acc + site.totalBuildingArea;
  }, 0);
  return total;
};
