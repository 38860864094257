import {
  CREATED_AT_JAN_1_2023,
  generateId,
  mapboxKey,
  useRelatedOrganisationList,
  useSite,
} from "@inrange/building-manager-api-client";
import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import {
  Building,
  NewSite,
  SiteOwnership,
} from "@inrange/building-manager-api-client/models-site";
import sqmToSqFtRounded from "@inrange/calculations/sqmToSqFtRounded";
import { useBuildingsByBounds } from "@inrange/inrange-data-api-client";
import {
  SiteCalculationsContext,
  SiteCalculationsProvider,
  SitePreview,
  useContextTS,
} from "@inrange/shared-components";
import {
  AddSiteConfig,
  AddSiteFormValidity,
  AddSiteMap,
  Grid,
  NewSiteValues,
} from "@inrange/theme-components";
import { Suggestion } from "@inrange/theme-components/mapping";
import center from "@turf/center";
import { points } from "@turf/helpers";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../auth/UserContext";

interface AddSiteProps {
  organisation: Organisation;
  setAddSiteCrumb: (crumb: string) => void;
}

const addressWithoutPostcode = (address: string): string => {
  let addressParts = address.split(",");
  addressParts = addressParts.slice(0, 2);
  return addressParts.join(",");
};

const mapboxAddressSearch = (lon: number, lat: number): string => {
  return `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${mapboxKey}&autocomplete=true`;
};

const checkIsSiteNameUnchanged = (
  siteName: string,
  address: string,
  organisationName: string
): boolean => {
  // first get the site name set by the address search
  if (!siteName) return false;
  const siteNameWithOrg =
    addressWithoutPostcode(address) + " \u1806 " + organisationName;
  // we then split the site name by the unicode character and take the first two parts
  const newSiteNameSplit = siteName.split(" \u1806 ");
  const newSiteNameWithOrg =
    newSiteNameSplit[0] + " \u1806 " + newSiteNameSplit[1];
  return siteNameWithOrg === newSiteNameWithOrg;
};

const AddSite: React.FC<AddSiteProps> = ({ organisation, setAddSiteCrumb }) => {
  const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { createSite } = useSite({
    userOrgId: organisation.id,
    app: "customer",
  });
  const siteId = generateId();

  const [newSite, setNewSite] = useState<NewSite>({
    id: siteId,
    name: "",
    buildingProfile: organisation.buildingProfile || "retail",
  });
  const [formValidity, setFormValidity] = useState<AddSiteFormValidity>({
    building: false,
    name: false,
    addressSearched: false, // This isn't referenced explicitly, but is used to trigger the form after the user has searched for an address
  });
  const [address, setAddress] = useState<string>("");
  const [mapBounds, setMapBounds] = useState<any>();
  const [relatedOrgName, setRelatedOrgName] = useState<string>("");
  const [newSiteValues, setNewSiteValues] = useState<NewSiteValues>({});
  const [addressError, setAddressError] = useState<boolean>(false);
  const [position, setPosition] = useState<[number, number]>([
    52.3555, -1.6743,
  ]);
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  const [buildingsInBounds, setBuildingsInBounds] = useState<
    Record<string, any>
  >({});
  const [renderedBuildings, setRenderedBuildings] = useState<
    Record<string, any>
  >({});
  const [kwpPerSqm, setKwpPerSqm] = useState<number>(0);
  const totalBuildingArea = Object.values(renderedBuildings).reduce(
    (acc, b) => acc + b.surface_area_sqm,
    0
  );
  const isLandlord = organisation.orgType === "Landlord";
  const orgId = organisation.id;
  const orgName = organisation.name;

  const setInstalledCapacityUsingKwpPerSqm = useCallback(
    (updatedKwpPerSqm: number) => {
      const updatedInstalledCapacity =
        totalBuildingArea * updatedKwpPerSqm * 0.35;
      setNewSite((prevNewSite) => {
        return { ...prevNewSite, installedCapacity: updatedInstalledCapacity };
      });
      setKwpPerSqm(updatedKwpPerSqm);
    },
    [totalBuildingArea, setKwpPerSqm, setNewSite]
  );

  // Fetch all buildings in the map bounds
  const fetchBuildingsInBounds = useBuildingsByBounds(mapBounds);

  // Fetch list of all organisations related to this organisation
  const { fetchRelatedOrganisations } = useRelatedOrganisationList(
    orgId,
    orgId
  );
  const relatedOrgList = fetchRelatedOrganisations.data?.organisations || [];

  useEffect(() => {
    if (!fetchBuildingsInBounds.data) return;

    // If we're too zoomed out, don't render billions of buildings
    const mapSizeMeters = mapBounds
      .getNorthEast()
      .distanceTo(mapBounds.getSouthWest());
    if (mapSizeMeters > 7000) return;

    const unselectedBuildings: Record<string, any> = {};
    Object.keys(fetchBuildingsInBounds.data).forEach((key) => {
      if (!renderedBuildings[key]) {
        unselectedBuildings[key] = fetchBuildingsInBounds.data[key];
      }
    });
    setBuildingsInBounds(unselectedBuildings);
  }, [fetchBuildingsInBounds.data, renderedBuildings, mapBounds]);

  const setSiteNameAndCrumb = (siteName: string, relatedOrgName?: string) => {
    if (relatedOrgName) {
      setRelatedOrgName(relatedOrgName);
    }
    const siteNameWithOrg =
      addressWithoutPostcode(address) + " \u1806 " + organisation.name;
    const isSiteNameUnchanged = checkIsSiteNameUnchanged(
      siteName,
      address,
      organisation.name
    );
    // If the user has changed the site name, use that. Otherwise,
    // add the related org name to the site name if it exists
    if (isSiteNameUnchanged) {
      if (relatedOrgName) {
        siteName = siteNameWithOrg + " \u1806 " + relatedOrgName;
      } else {
        siteName = siteNameWithOrg;
      }
    }
    setFormValidity({ ...formValidity, name: siteName.length > 0 });
    setNewSite({ ...newSite, name: siteName });
    const crumb = siteName.length > 0 ? `${siteName} (unsaved)` : "Add a site";
    setAddSiteCrumb(crumb);
  };

  const addressSearchedEvent = (address: string) => {
    trackUserInteraction(
      { address },
      "ADD_SITE",
      "ADD_SITE_ADDRESS_SEARCHED",
      user!.email.toLowerCase(),
      "customer-app"
    );
  };

  const suggestionHandler = (suggestion: Suggestion | undefined) => {
    if (!suggestion) {
      setAddressError(true);
      setAddress("");
      return;
    }
    const center = suggestion.center;
    const lat = center[1];
    const long = center[0];
    const address = suggestion.place_name;
    setAddress(suggestion.place_name);
    const siteNamePrefix = `${addressWithoutPostcode(address)} \u1806 ${
      organisation.name
    }`;
    const siteNameSuffix = relatedOrgName ? ` \u1806 ${relatedOrgName}` : "";
    const siteName = siteNamePrefix + siteNameSuffix;

    const crumb = `${siteName} (unsaved)`;
    setAddSiteCrumb(crumb);
    setFormValidity({ ...formValidity, name: siteName.length > 0 });

    setRenderedBuildings({});
    setFormValidity({
      ...formValidity,
      addressSearched: true,
      name: true,
      building: false,
    });
    setAddressError(false);
    setNewSite({
      ...newSite,
      name: siteName,
      address: address,
    });
    setPosition([lat, long]);
    addressSearchedEvent(address);
  };

  // Controllers to add and remove the buildings from selected buildings
  const setBuildingsForPreview = (buildings: any[]) => {
    const previewBuildings = buildings.map((b) => {
      return {
        id: b.key,
        surfaceAreaSqM: b.surface_area_sqm,
        siteID: siteId,
        geometry: b.geometry,
      } as Building;
    });
    setNewSite({ ...newSite, buildings: previewBuildings });
  };

  const buildingClickedEvent = (
    buildingArea: number,
    latitude: number,
    longitude: number,
    buildingKey: string,
    buildingAdded: boolean
  ) => {
    const buildingAreaFt = sqmToSqFtRounded(buildingArea);
    fetch(mapboxAddressSearch(longitude, latitude))
      .then((res) => res.json())
      .then((data) => {
        const buildingAddress =
          data?.features.find((feature) =>
            feature.place_type.includes("postcode")
          ).place_name || "No address found";

        trackUserInteraction(
          {
            buildingArea: buildingAreaFt,
            buildingAdded,
            latitude,
            longitude,
            buildingKey,
            address: buildingAddress,
          },
          "ADD_SITE",
          "ADD_SITE_BUILDING_CLICKED",
          user!.email.toLowerCase(),
          "customer-app"
        );
      });
  };

  const computeInstalledCapacity = (updatedBuildings: Record<string, any>) => {
    const updatedTotalBuildingArea = Object.values(updatedBuildings).reduce(
      (acc, b) => acc + b.surface_area_sqm,
      0
    );
    const updatedInstalledCapacity =
      updatedTotalBuildingArea * kwpPerSqm * 0.35;
    setNewSite((prevNewSite) => {
      return { ...prevNewSite, installedCapacity: updatedInstalledCapacity };
    });
  };

  const removeBuilding = (key: string) => {
    const { [key]: _value, ...updatedRenderedBuildings } = renderedBuildings;
    const building = renderedBuildings[key];
    const buildingArea = building.surface_area_sqm;
    const buildingKey = building.key;
    const buildingCenter = center(building.geometry).geometry.coordinates;
    const latitude = buildingCenter[1];
    const longitude = buildingCenter[0];

    setFormValidity({
      ...formValidity,
      building: Object.values(updatedRenderedBuildings).length > 0,
    });
    setRenderedBuildings(updatedRenderedBuildings);
    computeInstalledCapacity(updatedRenderedBuildings);
    setBuildingsForPreview(Object.values(updatedRenderedBuildings));
    buildingClickedEvent(buildingArea, latitude, longitude, buildingKey, false);
  };

  const addBuilding = (key: string) => {
    const building = buildingsInBounds[key];
    const buildingArea = building.surface_area_sqm;
    const buildingKey = building.key;
    const buildingPoints = building.geometry.coordinates[0];
    const latitude = buildingPoints[0][1];
    const longitude = buildingPoints[0][0];
    setFormValidity({ ...formValidity, building: true });
    const updatedRenderedBuildings = {
      ...renderedBuildings,
      [key]: buildingsInBounds[key],
    };
    setRenderedBuildings(updatedRenderedBuildings);
    computeInstalledCapacity(updatedRenderedBuildings);
    setBuildingsForPreview(Object.values(updatedRenderedBuildings));
    buildingClickedEvent(buildingArea, latitude, longitude, buildingKey, true);
  };

  const siteValid = formValidity.name && formValidity.building;

  const handleAddSite = (
    siteOwnerships: SiteOwnership[],
    siteValues: NewSiteValues
  ) => {
    setShowValidationErrors(true);
    setNewSiteValues(siteValues);

    if (siteValid) {
      const buildingsForNewSite = Object.values(renderedBuildings);
      const newSiteBuildingCenters = Object.values(buildingsForNewSite).map(
        (building) => center(building.geometry).geometry.coordinates
      );
      const centerOfAllNewSiteBuildings = center(
        points(newSiteBuildingCenters)
      );
      const latitude = centerOfAllNewSiteBuildings.geometry.coordinates[1];
      const longitude = centerOfAllNewSiteBuildings.geometry.coordinates[0];
      const site = {
        ...newSite,
        ppaLength: siteValues.sitePpaLength || 10,
        siteOwnerships: siteOwnerships,
        buildings: buildingsForNewSite,
        latitude,
        longitude,
      };

      if (siteValues.siteTenantAnnualDemandKwh) {
        site.demandCoefficientKWhPerM2 =
          siteValues.siteTenantAnnualDemandKwh / totalBuildingArea;
      }
      if (!siteValues.generator) {
        // If the site is not a generator, set the installed capacity to 0 to represent offtaker sites
        site.installedCapacity = 0;
        // Set the network import tariff to 0.25 for offtaker sites
        site.networkImportTariff = 0.25;
      }
      if (testMode) {
        site.createdAt = CREATED_AT_JAN_1_2023;
      }
      const sitePayload = { site };
      createSite.mutate(sitePayload);
    }
  };

  if (createSite.isError) {
    if (createSite.isError) {
      console.error(`Error creating site: `, createSite.error);
    }
  }

  useEffect(() => {
    if (createSite.isSuccess) {
      const response = createSite.data;
      const newSiteId = response.site_id;
      trackUserInteraction(
        {
          newOrg: newSiteValues.isNewOrg,
          relatedOrgType: newSiteValues.orgType,
          relatedOrgName: newSiteValues.orgName,
          generator: newSiteValues.generator,
          siteName: newSite.name,
          siteID: newSiteId,
          ppaLength: newSiteValues.sitePpaLength,
          demand: newSiteValues.siteTenantAnnualDemandKwh || 0,
        },
        "ADD_SITE",
        "ADD_SITE_CREATED",
        user!.email.toLowerCase(),
        "customer-app"
      );
      navigate(`/org/${organisation.id}/site/${response.site_id}`);
    }
  }, [
    createSite.isSuccess,
    createSite.data,
    newSite,
    organisation.id,
    newSiteValues,
    user,
    navigate,
  ]);

  return (
    <SiteCalculationsProvider userOrgId={orgId} site={newSite} app="customer">
      <SitePreview previewSite={newSite} originalSite={{}}>
        <AddSiteView
          position={position}
          isLandlord={isLandlord}
          orgId={orgId}
          orgName={orgName}
          buildingsInBounds={buildingsInBounds}
          setMapBounds={setMapBounds}
          addBuilding={addBuilding}
          removeBuilding={removeBuilding}
          renderedBuildings={renderedBuildings}
          formValidity={formValidity}
          showValidationErrors={showValidationErrors}
          suggestionHandler={suggestionHandler}
          newSite={newSite}
          addressError={addressError}
          relatedOrgList={relatedOrgList}
          setSiteNameAndCrumb={setSiteNameAndCrumb}
          handleAddSite={handleAddSite}
          siteCreating={createSite.isLoading}
          landlordOrgsLoading={fetchRelatedOrganisations.isLoading}
          setInstalledCapacityUsingKwpPerSqm={
            setInstalledCapacityUsingKwpPerSqm
          }
        />
      </SitePreview>
    </SiteCalculationsProvider>
  );
};

interface AddSiteViewProps {
  position: [number, number];
  isLandlord: boolean;
  orgId: string;
  orgName: string;
  buildingsInBounds: Record<string, any>;
  setMapBounds: (bounds: any) => void;
  addBuilding: (key: string) => void;
  removeBuilding: (key: string) => void;
  renderedBuildings: Record<string, any>;
  formValidity: AddSiteFormValidity;
  showValidationErrors: boolean;
  suggestionHandler: (suggestion: Suggestion | undefined) => void;
  newSite: NewSite;
  addressError: boolean;
  relatedOrgList: any[];
  setSiteNameAndCrumb: (siteName: string, relatedOrgName?: string) => void;
  handleAddSite: (
    siteOwnerships: SiteOwnership[],
    siteValues: NewSiteValues
  ) => void;
  siteCreating: boolean;
  landlordOrgsLoading: boolean;
  setInstalledCapacityUsingKwpPerSqm: (kwpPerSqm: number) => void;
}

const AddSiteView: React.FC<AddSiteViewProps> = ({
  position,
  isLandlord,
  orgId,
  orgName,
  buildingsInBounds,
  setMapBounds,
  addBuilding,
  removeBuilding,
  renderedBuildings,
  formValidity,
  showValidationErrors,
  suggestionHandler,
  newSite,
  addressError,
  relatedOrgList,
  setSiteNameAndCrumb,
  handleAddSite,
  siteCreating,
  landlordOrgsLoading,
  setInstalledCapacityUsingKwpPerSqm,
}) => {
  const { siteCalculations, isCalculating } = useContextTS(
    SiteCalculationsContext
  );

  const defaultDemand = parseFloat(
    siteCalculations.tenantAnnualDemandKwh.toFixed(2)
  );
  const kwpPerSqm = siteCalculations.halfHourlyGeneration?.kwpPerSqm || 0;

  useEffect(() => {
    setInstalledCapacityUsingKwpPerSqm(kwpPerSqm);
  }, [kwpPerSqm, setInstalledCapacityUsingKwpPerSqm]);

  return (
    <Grid $cols={2} $colwidth={"1fr"} $gap={"5px"} $margin={"5px 0px 0px 0px"}>
      <AddSiteMap
        position={position}
        mapZoom={6.3}
        buildingsInBounds={buildingsInBounds}
        setMapBounds={setMapBounds}
        addBuilding={addBuilding}
        removeBuilding={removeBuilding}
        selectedBuildings={renderedBuildings}
        buildingValidity={formValidity.building}
        showValidationErrors={showValidationErrors}
        fitWidth={true}
        height={"calc(100vh - 92px)"}
      />
      <AddSiteConfig
        suggestionHandler={suggestionHandler}
        siteName={newSite.name}
        addressError={addressError}
        isLandlord={isLandlord}
        orgId={orgId}
        orgName={orgName}
        relatedOrgList={relatedOrgList}
        setSiteNameAndCrumb={setSiteNameAndCrumb}
        formValidity={formValidity}
        handleAddSite={handleAddSite}
        showValidationErrors={showValidationErrors}
        siteCreating={siteCreating}
        isCalculating={isCalculating}
        orgDropdownLoading={landlordOrgsLoading && !isLandlord}
        defaultDemand={defaultDemand}
        isLoading={kwpPerSqm === 0}
      />
    </Grid>
  );
};

export default AddSite;
