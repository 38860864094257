import { OrgSiteListEntry } from "@inrange/building-manager-api-client/models-organisation";
import {
  NetworkSite,
  SdmOffer,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import React, { useState } from "react";
import { fractionalCurrencySymbol } from "../../formatting/currency-utils";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import formatPercentage from "../../formatting/formatPercentage";
import roundCurrency from "../../formatting/roundCurrency";
import displayId from "../../utils/displayId";
import { Row } from "../layout/Flex";
import {
  ColorHighlighted,
  ColorHighlightedWhite,
  Confirmation,
  Description,
  MarketplaceButton,
  OfferComparison,
  OfferComparisonSectionAfter,
  OfferDetailHeader,
  OfferDetails,
  OfferDetailSection,
  OfferList,
} from "./marketplace-styles";
import {
  getIRR,
  getRevenue,
  siteCanOnlySellThroughWire,
} from "./marketplace-utils";
import WithReturnToExploreLink from "./WithReturnToExploreLink";
import YourSiteTodayBeforeSellOffer from "./YourSiteTodayBeforeSellOffer";

interface MarketplaceSellOfferDetailsProps {
  site: Site;
  sdmOffer: SdmOffer;
  offerSite: OrgSiteListEntry | NetworkSite;
  userEmail: string;
  acceptOfferFn: () => void;
  setSelectedSiteIdFn: (siteId?: string) => void;
}

const MarketplaceSellOfferDetails: React.FC<
  MarketplaceSellOfferDetailsProps
> = ({ site, sdmOffer, offerSite, acceptOfferFn, setSelectedSiteIdFn }) => {
  const [acceptedOffer, setAcceptedOffer] = useState(false);

  const totalExportableEnergy =
    site.energyFlowAnnual.networkExport +
    site.energyFlowAnnual.exported +
    (site.exportLimit === 0 ? site.energyFlowAnnual.curtailed : 0);
  const newNetworkExportSdmMatches = [
    ...site.sdmMatches,
    ...(site.energyFlowAnnual.exported - sdmOffer.config.volume > 0
      ? [
          {
            sellerId: site.id,
            buyerId: "spill",
            volume: site.energyFlowAnnual.exported - sdmOffer.config.volume,
            tariff: site.inrangeExportTariff,
          },
        ]
      : []),
    sdmOffer.config,
  ].sort((a, b) => {
    const aTariff = a.tariff;
    const bTariff = b.tariff;
    // Sort by tariff - highest to lowest
    if (aTariff !== bTariff) {
      return bTariff - aTariff;
    }
    // Put the new offer on top if it's the same price
    if (a === sdmOffer.config) {
      return -1;
    }
    // Otherwise fall back to sorting by volume descending
    return b.volume - a.volume;
  });
  const totalSoldEnergy = newNetworkExportSdmMatches.reduce(
    (acc, match) => acc + match.volume,
    0
  );

  return (
    <>
      <Description data-testid={`marketplace-offer-description-loading-false`}>
        {"We've"} found a site{" "}
        {siteCanOnlySellThroughWire(site)
          ? "within this park"
          : "on the InRange network"}{" "}
        <ColorHighlighted>
          offering to buy{" "}
          {formatPercentage(sdmOffer.config.volume / totalExportableEnergy)} of
          the excess energy
        </ColorHighlighted>{" "}
        from your site at {(sdmOffer.config.tariff * 100).toFixed(2)}
        {fractionalCurrencySymbol(site.currencyCode)} / kWh, raising the{" "}
        <ColorHighlighted>
          IRR to {formatPercentage(getIRR(site, sdmOffer.financialModels))} a
          year
        </ColorHighlighted>
        .
      </Description>
      <OfferDetails>
        <OfferDetailSection>
          <OfferDetailHeader>
            <ColorHighlighted>Offer terms</ColorHighlighted>
          </OfferDetailHeader>
          <div>
            <b>Amount:</b>{" "}
            {formatUnitsToNearestTen(sdmOffer.config.volume, "kWh")} / year
          </div>
          <div>
            <b>Price:</b> {(sdmOffer.config.tariff * 100).toFixed(2)}
            {fractionalCurrencySymbol(site.currencyCode)} / kWh fixed for{" "}
            {sdmOffer.config.ppaLength} years
          </div>
        </OfferDetailSection>
        <OfferDetailSection>
          <OfferDetailHeader>
            <ColorHighlighted>Buying site</ColorHighlighted>
          </OfferDetailHeader>
          {!(offerSite as NetworkSite).isNetwork && (
            <div>
              <b>Name:</b> {(offerSite as OrgSiteListEntry).name}
            </div>
          )}
          {(offerSite as NetworkSite).isNetwork && (
            <div>
              <b>ID:</b> {displayId(sdmOffer.config.buyerId)}
            </div>
          )}
          <div>
            <b>Distance:</b> {sdmOffer.dest.km} km
          </div>
        </OfferDetailSection>
      </OfferDetails>
      <Description data-testid={`marketplace-offer-description-loading-false`}>
        How would <ColorHighlighted>accepting this offer</ColorHighlighted>{" "}
        impact your {"site's"} financial performance?
      </Description>
      <OfferComparison>
        <YourSiteTodayBeforeSellOffer
          site={site}
          renderBlankOfferRow={
            site.energyFlowAnnual.exported - sdmOffer.config.volume > 0
          }
        />
        <OfferComparisonSectionAfter>
          <OfferDetailHeader>
            <ColorHighlightedWhite>
              Your site if this offer is accepted
            </ColorHighlightedWhite>
          </OfferDetailHeader>
          <div>
            {newNetworkExportSdmMatches.length} buyer
            {newNetworkExportSdmMatches.length > 1 ? "s" : ""} of{" "}
            {formatPercentage(totalSoldEnergy / totalExportableEnergy, 1, true)}{" "}
            of your exportable energy
            <OfferList>
              {newNetworkExportSdmMatches.map((match) => (
                <li key={match.buyerId}>
                  {match.buyerId === sdmOffer.config.buyerId ? (
                    <>
                      <b>{formatUnitsToNearestTen(match.volume, "kWh")}</b> /
                      year{" "}
                      <b>
                        @ {(match.tariff * 100).toFixed(2)}
                        {fractionalCurrencySymbol(site.currencyCode)}
                      </b>{" "}
                      (this offer)
                    </>
                  ) : (
                    <>
                      {formatUnitsToNearestTen(match.volume, "kWh")} @{" "}
                      {(match.tariff * 100).toFixed(2)}
                      {fractionalCurrencySymbol(site.currencyCode)}
                    </>
                  )}
                </li>
              ))}
            </OfferList>
          </div>
          <div>
            {formatUnitsToNearestTen(site.energyFlowAnnual.behindMeter, "kWh")}{" "}
            sold on-site @ {(site.tenantTariff * 100).toFixed(2)}
            {fractionalCurrencySymbol(site.currencyCode)}
          </div>
          <div>
            <b>{formatPercentage(getIRR(site, sdmOffer.financialModels))}</b>{" "}
            IRR
          </div>
          <div>
            <b>
              {formatCurrencyToNearestTen(
                roundCurrency(getRevenue(site, sdmOffer.financialModels)),
                site.currencyCode,
                0,
                false
              )}
            </b>{" "}
            revenue / year
          </div>
        </OfferComparisonSectionAfter>
      </OfferComparison>
      <Row
        $padding={"0"}
        $margin={"10px 0 0 0"}
        $justifyContent={"end"}
        $alignItems={"start"}
      >
        {!acceptedOffer && (
          <WithReturnToExploreLink setSelectedSiteIdFn={setSelectedSiteIdFn}>
            <MarketplaceButton
              height="60px"
              $width="200px"
              fontWeight="400"
              fontSize="14px"
              label={`Accept offer / tell me more!`}
              onClick={() => {
                setAcceptedOffer(true);
                acceptOfferFn();
              }}
              data-testid="marketplace-accept-offer-button"
            />
          </WithReturnToExploreLink>
        )}
        {acceptedOffer && (
          <>
            <Confirmation>{`A member of our team will reach out shortly to complete your offer request.`}</Confirmation>
            <WithReturnToExploreLink setSelectedSiteIdFn={setSelectedSiteIdFn}>
              <MarketplaceButton
                height="60px"
                $width="200px"
                fontWeight="400"
                fontSize="14px"
                fontColor={"#000"}
                label={"Your request was sent"}
                disabled={true}
              />
            </WithReturnToExploreLink>
          </>
        )}
      </Row>
    </>
  );
};

export default MarketplaceSellOfferDetails;
